<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div
      v-if="validToken"
      class="auth-inner py-2"
    >
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <img
            src="/nirvana-memorial-garden-logo.png"
            alt="Logo"
          >
        </b-link>

        <b-card-title class="mb-1">
          Reset Password
        </b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previous password.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
              label="New Password"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:6"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="passwordValidation"
                  class="text-danger"
                >
                  {{ passwordError }}
                </small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password|min:6"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="confirmPasswordValidation"
                  class="text-danger"
                >
                  {{ confirmPasswordError }}
                </small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name:'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to Login
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>

    <not-found-error
      v-if="invalidToken"
    />
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Loader from '../../front-end/Loader.vue'
import NotFoundError from '../error/NotFoundError.vue'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,

    Loader,
    NotFoundError,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      passwordError: 'Valid Password is required',
      passwordValidation: false,
      confirmPasswordError: 'Valid Password is required',
      confirmPasswordValidation: false,
      invalidToken: false,
      validToken: false,
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  beforeMount() {
    const formData = new FormData()

    formData.append('email', this.$route.query.email)
    formData.append('token', this.$route.params.token)

    this.$http.post('users/reset-password/validate-token', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(response => {
        if (response.data.valid === false) {
          this.invalidToken = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.validToken = true
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('email', this.$route.query.email)
          formData.append('token', this.$route.params.token)
          formData.append('password', this.password)
          formData.append('confirmPassword', this.cPassword)

          this.$http.post('users/reset-password', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              const { accessToken, userData } = response.data
              store.commit('auth/auth_success', { accessToken, userData })
              this.$ability.update(userData.permissions)
              this.$router.replace(userData.userRedirectURL || '/')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.name || userData.email}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'password') {
                    this.passwordError = validationError.msg
                    this.passwordValidation = true
                  } else if (validationError.param === 'confirmPassword') {
                    this.confirmPasswordError = validationError.msg
                    this.confirmPasswordValidation = true
                  } else if (validationError.param === 'email') {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Email is Required',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  } else if (validationError.param === 'token') {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Reset Token is Required',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
